import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { PaymentService, PAYMENT_SERVICE } from '@/services';

@Module({ namespaced: true })
export class PaymentModule extends VuexModule {
  /**
   * Holds the payment config service.
   *
   * @var {PaymentService}
   */
  @Inject(PAYMENT_SERVICE)
  private _paymentService!: PaymentService;

  @Action
  public async bulk(payments: object): Promise<object> {
    return this._paymentService.bulk(payments);
  }

  @Action
  public async pay(payment: object): Promise<object> {
    return this._paymentService.pay(payment);
  }

  @Action
  public async payments(): Promise<object> {
    return this._paymentService.payments();
  }
}
