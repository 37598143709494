import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

// Register Vue I18N plugin.
Vue.use(VueI18n);

/**
 * Load the locale messages.
 *
 * @return {LocaleMessages}
 *   An object which holds the locale messages.
 */
function loadLocaleMessages(): LocaleMessages {
  // Resolve the locales directory filter by given expression.
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  // Initialize messages variable to an empty object. This will contain the resolved
  // locales files.
  const messages: LocaleMessages = {};
  // Iterate through the available locales.
  locales.keys().forEach(key => {
    // Extract the locale string from the filename.
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    // Check whether a match was found.
    if (matched && matched.length > 1) {
      // Get the matched locale.
      const locale = matched[1];
      // Append the locale messages for given key.
      messages[locale] = locales(key);
    }
  });

  return messages;
}

/**
 * Default Vue I18N instance.
 *
 * @var {VueI18n}
 */
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
