import { NavigationGuardNext, Route } from 'vue-router';
import { get } from 'lodash';
import store from '@/store';

/**
 * Navigation guard which requires user have a specific role if a route
 * annotated with role meta-data.
 *
 * @param {Route} to
 *   Route to which navigation will be performed.
 * @param {Route} from
 *   Previously active route.
 * @param {NavigationGuardNext} next
 *   Function used resolve guard result.
 */
export function roleGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  Promise.resolve()
    .then(async () => {
      // Get a list of required user roles.
      const requiredRoles = get(to.meta, 'requireRole') || null;
      // Determine whether current route has at least one role configured.
      if (Array.isArray(requiredRoles)) {
        // Get user from current session.
        const user = store.getters['app/user'];
        // Determine whether current user has at least one of the roles.
        if (!user || !requiredRoles.some(role => user.roles.includes(role))) {
          // Redirect to access denied page.
          return { name: 'accessDenied' };
        }
      }
    })
    .then(next)
    .catch(next);
}
