import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { TenantService, TENANT_SERVICE } from '@/services';

@Module({ namespaced: true })
export class TenantModule extends VuexModule {
  /**
   * Holds the Tenant config service.
   *
   * @var {TenantService}
   */
  @Inject(TENANT_SERVICE)
  private _tenantService!: TenantService;

  @Action
  public async add(tenant: object): Promise<object> {
    return this._tenantService.add(tenant);
  }

  @Action
  public async get(): Promise<object> {
    return this._tenantService.get();
  }

  @Action
  public async getAllByOrganizationId(id: string): Promise<object> {
    return this._tenantService.getAllByOrganizationId(id);
  }

  @Action
  public async delete(id: string): Promise<void> {
    return this._tenantService.delete(id);
  }

  @Action
  public async update(tenant: object): Promise<object> {
    return this._tenantService.update(tenant);
  }
}
