import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { OrganizationService, ORGANIZATION_SERVICE } from '@/services';

@Module({ namespaced: true })
export class OrganizationModule extends VuexModule {
  /**
   * Holds the Organization config service.
   *
   * @var {OrganizationService}
   */
  @Inject(ORGANIZATION_SERVICE)
  private _organizationService!: OrganizationService;

  @Action
  public async add(organization: object): Promise<object> {
    return this._organizationService.add(organization);
  }

  @Action
  public async get(id: string): Promise<object> {
    return this._organizationService.get(id);
  }

  @Action
  public async getAll(): Promise<object> {
    return this._organizationService.getAll();
  }

  @Action
  public async delete(id: string): Promise<void> {
    return this._organizationService.delete(id);
  }

  @Action
  public async update(organization: object): Promise<object> {
    return this._organizationService.update(organization);
  }
}
