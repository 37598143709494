import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { ProductService, PRODUCT_SERVICE } from '@/services';

@Module({ namespaced: true })
export class ProductModule extends VuexModule {
  /**
   * Holds the Product config service.
   *
   * @var {ProductService}
   */
  @Inject(PRODUCT_SERVICE)
  private _productService!: ProductService;

  @Action
  public async add(product: object): Promise<object> {
    return this._productService.add(product);
  }

  @Action
  public async delete(id: string): Promise<void> {
    return this._productService.delete(id);
  }

  @Action
  public async update(product: object): Promise<object> {
    return this._productService.update(product);
  }

  @Action
  public async getByTenant(): Promise<object> {
    return this._productService.getByTenant();
  }
}
