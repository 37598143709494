/**
 * Holds the HTTP Client Service name.
 *
 * @var {Symbol}
 */
export const HTTP_CLIENT_SERVICE = Symbol.for('HTTP_CLIENT_SERVICE');

/**
 * Interface which describes an HTTP Client service.
 */
export interface HttpClientService {
  /**
   * Create a request for given method.
   *
   * @param {string} method
   *   HTTP method which should be used during request.
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  request(method: string, input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a GET request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  get(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a DELETE request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  delete(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a HEAD request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  head(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a OPTIONS request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  options(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a POST request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  post(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a PUT request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  put(input: RequestInfo, init?: RequestInit): Promise<Response>;

  /**
   * Create a PATCH request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  patch(input: RequestInfo, init?: RequestInit): Promise<Response>;
}
