import { NavigationGuardNext, Route } from 'vue-router';
import { get } from 'lodash';
import store from '@/store';

/**
 * Navigation guard which requires user to be authenticated if a route
 * is marked as non public.
 *
 * @param {Route} to
 *   Route to which navigation will be performed.
 * @param {Route} from
 *   Previously active route.
 * @param {NavigationGuardNext} next
 *   Function used resolve guard result.
 */
export function authGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  Promise.resolve()
    .then(async () => {
      // Get indication whether route is marked as public.
      const isPublic = !!get(to.meta, 'public');
      // Check whether route is marked as private.
      if (isPublic || store.getters['app/isAuthenticated']) {
        // Allow navigation, regardless of authenticated state.
        next();
      } else {
        // Redirect to the login URL. We do not invoke next as
        // the browser will perform a redirect.
        window.location.href = '/auth/openid-connect/login';
      }
    })
    .catch(next);
}
