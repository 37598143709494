/**
 * Holds the Tenant Config Service name.
 *
 * @var {Tenant}
 */
export const TENANT_SERVICE = Symbol.for('TENANT_SERVICE');

/**
 * Interface which describes a Tenant Config service.
 */
export interface TenantService {
  add(tenant: object): Promise<object>;

  delete(id: string): Promise<void>;

  update(tenant: object): Promise<object>;

  get(): Promise<object>;

  getAllByOrganizationId(id: string): Promise<object>;
}
