/**
 * Holds the Organization Config Service name.
 *
 * @var {Organization}
 */
export const ORGANIZATION_SERVICE = Symbol.for('ORGANIZATION_SERVICE');

/**
 * Interface which describes a Organization Config service.
 */
export interface OrganizationService {
  add(organization: object): Promise<object>;

  delete(id: string): Promise<void>;

  update(organization: object): Promise<object>;

  get(id: string): Promise<object>;

  getAll(): Promise<object>;
}
