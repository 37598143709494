import { injectable } from 'inversify-props';
import { HttpClientService } from './definition';

export class HttpClientResponseError extends Error {
  /**
   *
   */
  public response!: Response;
}

@injectable()
export class FetchHttpClientService implements HttpClientService {
  /**
   * Create a request for given method.
   *
   * @param {string} method
   *   HTTP method which should be used during request.
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async request(method: string, input: RequestInfo, init?: RequestInit): Promise<Response> {
    // Build the actual init object and overwrite the method property.
    const actualInit = typeof init === 'object' ? { ...init, method } : { method };
    // Overwrite the method using the given method.
    const response = await fetch(new Request(input, actualInit));
    // Validate request has failed.
    if (response.status >= 400) {
      // Create an response for given status.
      const error = new HttpClientResponseError(response.statusText);
      // Attach the response object.
      error.response = response;

      throw error;
    }

    return response;
  }

  /**
   * Create a GET request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async get(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('GET', input, init);
  }

  /**
   * Create a DELETE request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async delete(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('DELETE', input, init);
  }

  /**
   * Create a HEAD request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async head(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('HEAD', input, init);
  }

  /**
   * Create a OPTIONS request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async options(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('OPTIONS', input, init);
  }

  /**
   * Create a POST request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async post(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('POST', input, init);
  }

  /**
   * Create a PUT request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async put(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('PUT', input, init);
  }

  /**
   * Create a PATCH request.
   *
   * @param {RequestInfo} input
   *   URL or request settings to be used during request.
   * @param {RequestInit} [init=undefined]
   *   Optional. Request settings which should be used.
   *
   * @return {Promise<Response>}
   *   A promise which holds the response.
   */
  public async patch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.request('PATCH', input, init);
  }
}
