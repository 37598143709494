import Vue from 'vue';
import Vuex from 'vuex';
import {
  AppModule,
  PaymentModule,
  OrganizationModule,
  TenantModule,
  ProductModule,
  PaylinkModule,
} from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: AppModule,
    payment: PaymentModule,
    organization: OrganizationModule,
    tenant: TenantModule,
    product: ProductModule,
    paylink: PaylinkModule,
  },
});
