import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { authGuard, roleGuard } from '@/guards';
import store from '@/store';
import { DEFAULT_ROUTES } from './default';
import { PAYMENT_ROUTES } from './payments';
import { PRODUCT_ROUTES } from './products';
import { ORGANIZATION_ROUTES } from './organizations';

// Register the Vue Router plugin.
Vue.use(VueRouter);

/**
 * Default Vue Router instance.
 *
 * @var {VueRouter}
 */
const router = new VueRouter({
  mode: 'history',
  routes: [...ORGANIZATION_ROUTES, ...PAYMENT_ROUTES, ...PRODUCT_ROUTES, ...DEFAULT_ROUTES],
});

// Global route callback which perform pre routing application specific logic.
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  Promise.resolve()
    // Refresh the current application session.
    .then(() => store.dispatch('app/refresh'))
    // Ignore any failure to refresh application session.
    .then(() => next())
    .catch(() => next());
});

// Global guards which provide access control.
router.beforeEach(authGuard);
router.beforeEach(roleGuard);

export default router;
