













































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Role } from '@/utility/enums';

@Component({})
export default class App extends Vue {
  @Getter('app/isAuthenticated')
  private isAuthenticated!: boolean;

  @Getter('app/user')
  private user!: any;

  hasAccessToBulk() {
    return this.user.roles.includes(Role.BulkPay);
  }

  hasAccessToProducts() {
    return this.user.roles.includes(Role.PaymentAdmin);
  }
}
