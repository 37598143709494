import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '@/services';

// FIXME: Provide required user interface.

@Module({ namespaced: true })
export class AppModule extends VuexModule {
  /**
   * Holds the resolved user information.
   *
   * @var {any|null}
   */
  public _user: any | null = null;

  /**
   * Holds the paymentsmodule config service.
   *
   * @var {HttpClientService}
   */
  @Inject(HTTP_CLIENT_SERVICE)
  private _httpClient!: HttpClientService;

  /**
   * Get a value indicating whether user has a session.
   *
   * @var {boolean}
   */
  public get isAuthenticated(): boolean {
    return !!this.user;
  }

  /**
   * Get current user information.
   *
   * @var {any|null}
   */
  public get user(): any | null {
    return this._user;
  }

  /**
   * Get the application environment.
   *
   * @var {string}
   */
  public get env(): string {
    return this.config.env || 'production';
  }

  /**
   * Get the application configuration.
   *
   * @var {any}
   */
  public get config(): any {
    // TODO: Provde interface and extend window to hold the config object in typings.
    const config = (window as any).__CONFIG__ as any;
    // Get actual configuration.
    return config || {};
  }

  /**
   * Transition application session.
   *
   * @param {any|null} user
   *   User information if available, otherwise null.
   */
  @Mutation
  public session(user: any | null): void {
    // Update session related class members.
    this._user = user;
  }

  /**
   * Get the current user context.
   *
   */
  @Action
  public async refresh(): Promise<void> {
    // Resolve the current user context based on server side information.
    const response = await this._httpClient.get('/api/v1/user');
    // Convert response into JSON object or fallback to null on failure.
    const user = await response.json().catch(() => null);
    // Update application session for given user information.
    this.context.commit('session', user);
  }
}
