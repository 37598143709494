/**
 * Roles which can be assigned to a user.
 */
export enum Role {
  /**
   * User can administer application.
   *
   * @var {string}
   */
  Admin = 'MBPAdminpaneelGebruiker-administrator',

  /**
   * User can only read and write configuration.
   *
   * @var {string}
   */
  Write = 'MBPAdminpaneelGebruiker-schrijver',

  /**
   * User can only read configurations.
   *
   * @var {string}
   */
  Read = 'MBPAdminpaneelGebruiker-raadpleger',

  /**
   * User can make manual payment.
   *
   * @var {string}
   */
  ManualPay = 'BetalingenGebruiker-manueel',

  /**
   * User can see completed payments.
   *
   * @var {string}
   */
  PaymentOverview = 'BetalingenGebruiker-betaaloverzicht',

  /**
   * User can make bulk payments.
   *
   * @var {string}
   */
  BulkPay = 'BetalingenGebruiker-bulk',

  /**
   * User is administrator.
   *
   * @var {string}
   */
  PaymentAdmin = 'BetalingenGebruiker-Admin',
}
