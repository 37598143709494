/**
 * Holds the Payment Config Service name.
 *
 * @var {Payment}
 */
export const PAYMENT_SERVICE = Symbol.for('PAYMENT_SERVICE');

/**
 * Interface which describes a Payment Config service.
 */
export interface PaymentService {
  pay(payment: object): Promise<object>;

  bulk(payments: object): Promise<object>;

  payments(): Promise<object>;
}
