import { Role } from '@/utility/enums';
import { RouteConfig } from 'vue-router';

/**
 * List of Payment related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const PAYMENT_ROUTES: RouteConfig[] = [
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/payments/PaymentOverview.vue'),
    meta: {
      requireRole: [Role.PaymentOverview],
    },
  },
  {
    path: '/manual',
    name: 'manual',
    component: () => import('@/views/payments/ManualPayment.vue'),
    meta: {
      requireRole: [Role.ManualPay],
    },
  },
  {
    path: '/bulk',
    name: 'bulk',
    component: () => import('@/views/payments/BulkPayment.vue'),
    meta: {
      requireRole: [Role.BulkPay],
    },
  },
  {
    path: '/paylink',
    name: 'paylink',
    meta: { public: true },
    component: () => import('@/views/payments/QRPaylink.vue'),
  },
];
