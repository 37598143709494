import { Container } from 'inversify-props';
import { FetchHttpClientService, HttpClientService, HTTP_CLIENT_SERVICE } from './http-client';
import { PaymentServiceImpl, PaymentService, PAYMENT_SERVICE } from './payment';
import { ProductServiceImpl, ProductService, PRODUCT_SERVICE } from './product';
import { OrganizationServiceImpl, OrganizationService, ORGANIZATION_SERVICE } from './organization';
import { TenantServiceImpl, TenantService, TENANT_SERVICE } from './tenant';
import { PaylinkServiceImpl, PaylinkService, PAYLINK_SERVICE } from './paylink';

export * from './http-client';
export * from './payment';
export * from './product';
export * from './organization';
export * from './tenant';
export * from './paylink';

/**
 * Register services which our dependency injection container.
 *
 * @param {Container} container
 *   An object which represents the dependency injection container.
 */
export default function registerServices(container: Container) {
  container.addTransient<HttpClientService>(FetchHttpClientService, HTTP_CLIENT_SERVICE);
  container.addTransient<PaymentService>(PaymentServiceImpl, PAYMENT_SERVICE);
  container.addTransient<ProductService>(ProductServiceImpl, PRODUCT_SERVICE);
  container.addTransient<OrganizationService>(OrganizationServiceImpl, ORGANIZATION_SERVICE);
  container.addTransient<TenantService>(TenantServiceImpl, TENANT_SERVICE);
  container.addTransient<PaylinkService>(PaylinkServiceImpl, PAYLINK_SERVICE);
}
