import { Inject, injectable } from 'inversify-props';
import { PaymentService } from './definition';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';

/**
 * Requested size of the payments collection individual pages.
 *
 * @var {number}
 */
const PAYMENTS_PAGE_SIZE = 100;

@injectable()
export class PaymentServiceImpl implements PaymentService {
  /**
   * Create a PaymentServiceImpl object.
   *
   * @param {HttpClientService} _httpClient
   *   An HTTP Client service.
   */
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  private async getPagedPayments(
    offset: number,
    limit: number,
  ): Promise<{
    items: object[];
    pageMetadata: { size: number; offset: number; totalItems: number };
  }> {
    return this._httpClient
      .get(this.createEndpointUrl('/api/v1/pom/payments', { offset, limit }).toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  pay(payment: object): Promise<object> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/pom/pay`);
    // Resolve search response data.
    return this._httpClient
      .post(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payment),
      })
      .then(response => response.json())
      .catch(error => {
        throw error;
      });
  }
  bulk(payments: object): Promise<object> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/pom/bulk`);
    // Resolve search response data.
    return this._httpClient
      .post(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payments),
      })
      .then(response => response.json())
      .catch(error => {
        throw error;
      });
  }
  async payments(): Promise<object[]> {
    // Get the first page of payments. This is needed to detect whether
    // additional fetching is required.
    let page = await this.getPagedPayments(0, PAYMENTS_PAGE_SIZE);
    // Use the items from the first page as initial dataset.
    let inMemoryDataset = page.items;
    // Loop through the pages until end is reached.
    while ((page.pageMetadata.offset + 1) * page.pageMetadata.size < page.pageMetadata.totalItems) {
      // Request next page based on the page metadata information.
      page = await this.getPagedPayments(page.pageMetadata.offset + 1, page.pageMetadata.size);
      // Only merge arrays if non-empty.
      if (page.items.length > 0) {
        // Merge existing items with page items.
        inMemoryDataset = inMemoryDataset.concat(page.items);
      }
    }

    return inMemoryDataset;
  }
  private createEndpointUrl(path: string, queryParams: object | null = null): string {
    // Create URL for given path.
    const url = new URL(path, window.location.origin);
    // Check whether a query params object is present.
    if (queryParams !== null && typeof queryParams === 'object') {
      // Iterate through the query parameter property names.
      for (const [key, value] of Object.entries(queryParams)) {
        // Append the query parameter key/value pair.
        url.searchParams.append(key, value);
      }
    }

    return url.toString();
  }
}
