import { Inject, injectable } from 'inversify-props';
import { ProductService } from './definition';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';

@injectable()
export class ProductServiceImpl implements ProductService {
  /**
   * Create a ProductServiceImpl object.
   *
   * @param {HttpClientService} _httpClient
   *   An HTTP Client service.
   */
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  delete(id: string): Promise<void> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/product/delete?id=${id}`);
    // Resolve search response data.
    return this._httpClient
      .delete(url.toString(), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        // do nothing
      })
      .catch(error => {
        throw error;
      });
  }
  add(product: object): Promise<object> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/product/add`);
    // Resolve search response data.
    return this._httpClient
      .post(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      })
      .then(response => response.json())
      .catch(error => {
        throw error;
      });
  }
  update(product: object): Promise<object> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/product/update`);
    // Resolve search response data.
    return this._httpClient
      .put(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      })
      .then(response => response.json())
      .catch(error => {
        throw error;
      });
  }
  getByTenant(): Promise<object> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/product/get`);
    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .catch(error => {
        throw error;
      });
  }

  private createEndpointUrl(path: string, queryParams: object | null = null): string {
    // Create URL for given path.
    const url = new URL(path, window.location.origin);
    // Check whether a query params object is present.
    if (queryParams !== null && typeof queryParams === 'object') {
      // Iterate through the query parameter property names.
      for (const [key, value] of Object.entries(queryParams)) {
        // Append the query parameter key/value pair.
        url.searchParams.append(key, value);
      }
    }

    return url.toString();
  }
}
