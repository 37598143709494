import { Role } from '@/utility/enums';
import { RouteConfig } from 'vue-router';

/**
 * List of Product related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const PRODUCT_ROUTES: RouteConfig[] = [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Overview.vue'),
    meta: {
      // add role?
      requireRole: [Role.PaymentAdmin],
    },
  },
];
