import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { PaylinkService, PAYLINK_SERVICE } from '@/services';

@Module({ namespaced: true })
export class PaylinkModule extends VuexModule {
  /**
   * Holds the Paylink config service.
   *
   * @var {PaylinkService}
   */
  @Inject(PAYLINK_SERVICE)
  private _paylinkervice!: PaylinkService;

  @Action
  public async add(paylink: object): Promise<object> {
    return this._paylinkervice.add(paylink);
  }

  @Action
  public async get(id: string): Promise<object> {
    return this._paylinkervice.get(id);
  }
}
