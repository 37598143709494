/**
 * Holds the Paylink Config Service name.
 *
 * @var {Paylink}
 */
export const PAYLINK_SERVICE = Symbol.for('PAYLINK_SERVICE');

/**
 * Interface which describes a Paylink Config service.
 */
export interface PaylinkService {
  add(paylink: object): Promise<object>;

  get(id: string): Promise<object>;
}
