/**
 * Holds the Product Config Service name.
 *
 * @var {Product}
 */
export const PRODUCT_SERVICE = Symbol.for('PRODUCT_SERVICE');

/**
 * Interface which describes a Product Config service.
 */
export interface ProductService {
  add(product: object): Promise<object>;

  delete(product: string): Promise<void>;

  update(product: object): Promise<object>;

  getByTenant(): Promise<object>;
}
